import Header from "../../components/Header/Header";
import styles from "./Products.module.scss";
import forbici from "../../assets/forbice.png";
import simplyZen from "../../assets/simplyzen.png";
import milkshake from "../../assets/milkshake.png";
import depot from "../../assets/depot.png";
import phoneIcon from "../../assets/phoneIcon.png";
import whatsappIcon from "../../assets/whatsappIcon.png";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Maps from "../../components/Maps/Maps";
import ItemRow from "../../components/ItemRow/ItemRow";

const Products: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center overflow-hidden">
        <div className={`${styles.firstDiv} col-12 mb-10 `}>
          <Header />

          <div
            className={`${styles.pageDiv}  mt-10  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
          >
            <>
              <span> Prodotti</span>

              <div className="d-flex flex-row align-items-center justify-content-center mt-7  col-9 ">
                <>
                  <div
                    className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                  >
                    <span
                      className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                    >
                      <div
                        className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center `}
                      >
                        <img className={` ${styles.icona}`} src={depot} />
                        <span
                          className={`${styles.title} subtitle-tahoma fw-bold`}
                        >
                          Depot
                        </span>
                      </div>
                    </span>
                    <span
                      className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                    >
                      <img className={` ${styles.icona}`} src={depot} />
                    </span>
                  </div>
                  <div
                    className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                  >
                    <span
                      className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                    >
                      <div
                        className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center `}
                      >
                        <img className={` ${styles.icona}`} src={milkshake} />
                        <span
                          className={`${styles.title} subtitle-tahoma fw-bold`}
                        >
                          Milk Shake
                        </span>
                      </div>
                    </span>
                    <span
                      className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                    >
                      <img className={` ${styles.icona}`} src={milkshake} />
                    </span>
                  </div>
                  <div
                    className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                  >
                    <span
                      className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                    >
                      <div
                        className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center `}
                      >
                        <img className={` ${styles.icona}`} src={simplyZen} />
                        <span
                          className={`${styles.title} subtitle-tahoma fw-bold`}
                        >
                          Simply Zen
                        </span>
                      </div>
                    </span>
                    <span
                      className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                    >
                      <img className={` ${styles.icona}`} src={simplyZen} />
                    </span>
                  </div>
                </>
              </div>
              <div className="mt-10 col-9  d-flex flex-row align-items-start justify-content-center ">
                <span className="col-3 d-flex flex-row align-items-start justify-content-start mx-5   text-lato pe-5 ">
                  <span>
                    Depot linea maschile. <br /> Nel nostro salone potrai
                    trovare prodotti per la cura e il benessere di ogni uomo,
                    personalizzando ogni rituale per le esigenze specifiche di
                    ognuno di noi. 
                  </span>
                </span>
                <span className="col-3 d-flex flex-row align-items-start justify-content-start mx-5  text-lato pe-5">
                  <span>
                    Milk Shake linea femminile. <br /> Cerchi sostenibilità,
                    ingredienti vegetali o in alternativa organici naturali?
                    Immergiti in un valore unico e sensoriale tramite
                    trattamenti avvolgenti, personalizzati per piccole o lunghe
                    pausa di benessere.
                  </span>
                </span>
                <span className="col-3 d-flex flex-row align-items-start justify-content-start mx-5 text-lato pe-5">
                  <span>
                    Simply Zen linea eco. <br /> Ritrova il benessere dei tuoi
                    capelli/corpo per il proprio equilibrio abbracciando
                    l’energia della natura.
                  </span>
                </span>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
