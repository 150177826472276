import { useNavigate } from "react-router-dom";

import styles from "./Header.module.scss";

import logo from "../../assets/logo.png";

const Header: React.FC = () => {
  const redirect = useNavigate();
  const path = window.location.pathname;

  return (
    <>
      <div
        className=" d-flex flex-column flex-md-row col-12 mt-4   align-items-md-center justify-content-md-between align-items-start justify-content-start "
        style={{
          backgroundColor: `transparent`,
          zIndex: 2,
          position: "relative",
        }}
      >
        <div
          className={`d-flex flex-row align-items-center justify-content-center col-12 mt-4 ${styles.headerContainer} `}
        >
          <div
            className={`col-2 d-flex flex-row align-items-center justify-content-center  fw-bold cursor-pointer ${
              path.includes("AboutUs") ? "color-white" : "color-greyText"
            }`}
            onClick={() => redirect("/AboutUs")}
          >
            Chi Siamo
          </div>
          <div
            className={`col-2 d-flex flex-row align-items-center justify-content-center  fw-bold cursor-pointer  ${
              path.includes("Pricing") ? "color-white" : "color-greyText"
            }`}
            onClick={() => redirect("/Pricing")}
          >
            Prezzi e Servizi
          </div>
          <div
            className={`col-2 ${styles.logoContainer}`}
            onClick={() => redirect("/")}
          >
            <img src={logo} className={`${styles.logoImg}`} />
          </div>
          <div
            className={`col-2 d-flex flex-row align-items-center justify-content-center  fw-bold cursor-pointer ${
              path.includes("Products") ? "color-white" : "color-greyText"
            }`}
            onClick={() => redirect("/Products")}
          >
            Prodotti
          </div>
          <div
            className={`col-2 d-flex flex-row align-items-center justify-content-center  fw-bold cursor-pointer ${
              path.includes("Contacts") ? "color-white" : "color-greyText"
            }`}
            onClick={() => redirect("/Contacts")}
          >
            Contatti ed Orari
          </div>
        </div>

        {/* MOBILE */}
        <div
          className="col-12 flex-row   d-flex d-md-none d-block align-items-start justify-content-start  "
        //   style={{ height: "25vh" }}
        ></div>
      </div>
    </>
  );
};
export default Header;
