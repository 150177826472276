import Header from "../../components/Header/Header";
import styles from "./Pricing.module.scss";
import forbici from "../../assets/forbice.png";
import seat from "../../assets/seat.png";
import mustache from "../../assets/mustache.png";
import arrow from "../../assets/arrow.png";
import phoneIcon from "../../assets/phoneIcon.png";
import whatsappIcon from "../../assets/whatsappIcon.png";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Maps from "../../components/Maps/Maps";
import ItemRow from "../../components/ItemRow/ItemRow";

const cu: Service[] = [
  {
    title: "Taglio uomo",
    price: "24€",
    descriprion:
      "Consulenza per la definizione dell'immagine incluso di shampoo e risciacquo ",
  },
  {
    title: "Taglio razor fade",
    price: "28€",
    descriprion: "",
  },
  {
    title: "Taglio bambino",
    price: "18€",
    descriprion: "",
  },
];

const cd: Service[] = [
  {
    title: "Taglio donna",
    price: "22€",
    descriprion: "Personalizzato con analisi della morfologia del visos",
  },
  {
    title: "Piega",
    price: "20€",
    descriprion:
      "Definizione dello stile desiderato, comprensivo di bagno e fluido adatto a tutti i tipi di capelli",
  },
  {
    title: "Consulenza/Diagnosi <br/> del cuoio capelluto",
    price: "Gratuita",
    descriprion: "",
  },
  {
    title: "Detossinazione cute",
    price: "15€",
    descriprion: "",
  },
  {
    title: "Ampolla anticaduta o densificante con massaggio",
    price: "8€",
    descriprion: "",
  },
];

const b: Service[] = [
  {
    title: "Barba 3.0",
    price: "22€",
    descriprion:
      "Tuffati nel passato dell'antico rituale accompagnata dal panno caldo tramandato alla 3 generazione",
  },
  {
    title: "Barba a macchinetta",
    price: "12€",
    descriprion: "",
  },
  {
    title: "Barba SPA",
    price: "35€",
    descriprion:
      "Tradizione e benessere una barba definita assieme a una pelle rivitalizzata come in SPA",
  },
];

const tu: Service[] = [
  {
    title: "Regressione",
    price: "22€",
    descriprion:
      "Dimentica i capelli bianchi restando te stesso con Elements color",
  },
  {
    title: "Latte detergente + scrub ",
    price: "6€",
    descriprion: "",
  },
  {
    title: "Maschera",
    price: "8€",
    descriprion: "",
  },
  {
    title: "Latte detergente + scrub + maschera",
    price: "13€",
    descriprion: "",
  },
];

const td: Service[] = [
  {
    title: "Physia Color",
    price: "30€",
    descriprion:
      "Colore con esclusiva formulazione arricchita in oli essenziali che rispetta il naturale equilibrio di cute e capelli",
  },
  {
    title: "Ventaglio ",
    price: "60€",
    descriprion:
      "Schiaritura totale per donare brillantezza e luminosità, avvolgendo il sole per ottenere un effetto naturale sui capelli",
  },
  {
    title: "Trattamento specifico",
    price: "15€",
    descriprion: "",
  },
  {
    title: "Correzione della struttura capillare",
    price: "38€",
    descriprion: "Permanente o stiratura",
  },
  {
    title: "Latte detergente + scrub ",
    price: "6€",
    descriprion: "",
  },
  {
    title: "Maschera",
    price: "8€",
    descriprion: "",
  },
  {
    title: "Latte detergente + scrub + maschera",
    price: "13€",
    descriprion: "",
  },
];

const Pricing: React.FC = () => {
  const [genderSelected, setGenderSelected] = useState("U");
  const [menuSelected, setMenuSelected] = useState("");

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center overflow-hidden">
        <div className={`${styles.firstDiv} col-12 mb-10 `}>
          <Header />

          <div
            className={`${styles.pageDiv}  mt-10  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
          >
            {menuSelected === "" && (
              <>
                <span> Prezzi e Servizi</span>
                <div
                  className={`col-12 d-flex flex-row align-items-center justify-content-center mt-7 subtitle-tahoma fw-bold`}
                >
                  <span
                    onClick={() => setGenderSelected("U")}
                    className={`me-3 cursor-pointer ${
                      genderSelected === "U" ? "color-white" : "color-greyText"
                    }`}
                  >
                    Uomo
                  </span>
                  |
                  <span
                    className={`ms-3 cursor-pointer ${
                      genderSelected === "D" ? "color-white" : "color-greyText"
                    }`}
                    onClick={() => setGenderSelected("D")}
                  >
                    Donna
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center mt-7  col-9">
                  {genderSelected === "U" && (
                    <>
                      <div
                        className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                      >
                        <span
                          className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                          onClick={() => setMenuSelected("CU")}
                        >
                          <span className="subtitle-tahoma fw-bold">
                            Capelli
                          </span>
                          <span className="text-tahoma  mt-5 text-center p-2">
                            Dalla rasatura a lama al taglio studiato nel minimo
                            particolare, qualunque sia il tuo stile troveremo il
                            modo per distinguerti
                          </span>
                          <div
                            className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center mt-5`}
                          >
                            <img className={` ${styles.icona}`} src={forbici} />
                          </div>
                        </span>
                        <span
                          className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                        ></span>
                      </div>
                      <div
                        className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                      >
                        <span
                          className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                          onClick={() => setMenuSelected("B")}
                        >
                          <span className="subtitle-tahoma fw-bold">Barba</span>
                          <span className="text-tahoma  mt-5 text-center p-2">
                            Lo stile della barba è come la scelta di un
                            accessorio e diventa fondamentale per la
                            valorizzazione del tuo viso
                          </span>
                          <div
                            className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center mt-5`}
                          >
                            <img
                              className={` ${styles.icona}`}
                              src={mustache}
                            />
                          </div>
                        </span>
                        <span
                          className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                        ></span>
                      </div>
                      <div
                        className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                      >
                        <span
                          className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                          onClick={() => setMenuSelected("TU")}
                        >
                          <span className="subtitle-tahoma fw-bold">
                            Trattamenti
                          </span>
                          <span className="text-tahoma  mt-5 text-center p-3">
                            Concludi in bellezza la tua sedutra con un
                            trattamento relax per i tuoi capelli, per
                            un'esperienza unica
                          </span>
                          <div
                            className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center mt-5`}
                          >
                            <img className={` ${styles.icona}`} src={seat} />
                          </div>
                        </span>
                        <span
                          className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                        ></span>
                      </div>
                    </>
                  )}
                  {genderSelected === "D" && (
                    <>
                      <div
                        className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                      >
                        <span
                          className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                          onClick={() => setMenuSelected("CD")}
                        >
                          <span className="subtitle-tahoma fw-bold">
                            Capelli
                          </span>
                          <span className="text-tahoma  mt-5 text-center ">
                            Dalla rasatura a lama al taglio studiato nel minimo
                            particolare, qualunque sia il tuo stile troveremo il
                            modo per distinguerti
                          </span>
                          <div
                            className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center mt-5`}
                          >
                            <img className={` ${styles.icona}`} src={forbici} />
                          </div>
                        </span>
                        <span
                          className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                        ></span>
                      </div>

                      <div
                        className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                      >
                        <span
                          className={`${styles.plan} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                          onClick={() => setMenuSelected("TD")}
                        >
                          <span className="subtitle-tahoma fw-bold">
                            Trattamenti
                          </span>
                          <span className="text-tahoma  mt-5 text-center p-3">
                            Concludi in bellezza la tua sedutra con un
                            trattamento relax per i tuoi capelli, per
                            un'esperienza unica
                          </span>
                          <div
                            className={` ${styles.imageContainer}  col-12 d-flex flex-row align-items-center justify-content-center mt-5`}
                          >
                            <img className={` ${styles.icona}`} src={seat} />
                          </div>
                        </span>
                        <span
                          className={`${styles.planTrasled} col-11 color-white d-flex flex-column align-items-center justify-content-start`}
                        ></span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {menuSelected !== "" && (
              <>
                <div
                  className={`col-9 d-flex flex-row  align-items-center justify-content-start ${styles.menu}`}
                >
                  <div className={`${styles.arrowContainer} col-1 `}>
                    <img
                      src={arrow}
                      className={`${styles.arrowImage} cursor-pointer`}
                      onClick={() => setMenuSelected("")}
                    />
                  </div>
                  <div className="d-flex flex-row col-10 align-items-center justify-content-center">
                    {menuSelected.includes("C") && "Capelli"}
                    {menuSelected.includes("B") && "Barba"}
                    {menuSelected.includes("T") && "Trattamenti"}
                  </div>
                  <div className="d-flex flex-row col-1 "></div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center  mt-10 col-5  ">
                  {menuSelected === "CU" &&
                    cu.map((item, index) => (
                      <ItemRow
                        key={index}
                        title={item.title}
                        description={item.descriprion}
                        price={item.price}
                      />
                    ))}
                  {menuSelected === "B" &&
                    b.map((item, index) => (
                      <ItemRow
                        key={index}
                        title={item.title}
                        description={item.descriprion}
                        price={item.price}
                      />
                    ))}
                  {menuSelected === "TU" &&
                    tu.map((item, index) => (
                      <ItemRow
                        key={index}
                        title={item.title}
                        description={item.descriprion}
                        price={item.price}
                      />
                    ))}
                  {menuSelected === "CD" &&
                    cd.map((item, index) => (
                      <ItemRow
                        key={index}
                        title={item.title}
                        description={item.descriprion}
                        price={item.price}
                      />
                    ))}
                  {menuSelected === "TD" &&
                    td.map((item, index) => (
                      <ItemRow
                        key={index}
                        title={item.title}
                        description={item.descriprion}
                        price={item.price}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
