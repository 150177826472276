import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Routes/Home/Home";
import Pricing from "./Routes/Pricing/Pricing";
import AboutUs from "./Routes/AboutUs/AboutUs";
import Contacts from "./Routes/Contacts/Contacts";
import Products from "./Routes/Products/Products";

const Container = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/Pricing"} element={<Pricing />} />
      <Route path={"/AboutUs"} element={<AboutUs />} />
      <Route path={"/Contacts"} element={<Contacts />} />
      <Route path={"/Products"} element={<Products />} />
    </Routes>
  );
};

export default Container;
