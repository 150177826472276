import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import styles from "./ItemRow.module.scss";

import logo from "../../assets/logo.png";

type ItemRowProps = {
  title: string;
  description: string;
  price: string;
};

const ItemRow: React.FC<ItemRowProps> = (props) => {
  const redirect = useNavigate();
  const path = window.location.pathname;

  return (
    <>
      <div
        className={`d-flex flex-column align-items-start justify-content-center col-12 px-5 py-5 ${styles.itemRow} `}
      >
        <div className="d-flex flex-row align-items-center justify-content-center col-12">
          <div className="d-flex flex-row   subtitle-tahoma fw-bold col-10 ">
            {parse(props.title)}
          </div>
          <div className="d-flex flex-row    subtitle-tahoma  col-2  align-items-center justify-content-end">
            {props.price}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center col-8 justify-content-start text-lato mt-4">
          {props.description}
        </div>
      </div>
    </>
  );
};
export default ItemRow;
