import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React from "react";
import { useEffect, useState, useMemo } from "react";

type MapProps = {
  center: google.maps.LatLngLiteral;
  containerStyle: React.CSSProperties;
  mapOptions: any;
  poiLocation: any;
};

const MemorizedMap: React.FC<MapProps> = React.memo(
  ({ center, containerStyle, mapOptions, poiLocation }) => (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      options={mapOptions}
      mapTypeId="satellite"
    >
      <Marker position={poiLocation} label="" />
    </GoogleMap>
  )
);

const Maps = () => {
  // Chiave API di Google Maps
  const apiKey = "AIzaSyAulnThrb-4u8ZBJf6v9G4xL4nbbwlXQ28";

  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // Coordinate del centro della mappa
  const mapCenter = useMemo(
    () => ({ lat: 45.37906761034888, lng: 11.902815047164092 }),
    []
  );

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "100%", borderRadius: "10px" }),
    []
  );

  const mapOptions = useMemo(
    () => ({ disableDefaultUI: true, zoomControl: true }),
    []
  );

  const poiLocation = { lat: 45.37906761034888, lng: 11.902815047164092 };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (
        document.querySelector(
          `script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}"]`
        )
      ) {
        if (window.google && window.google.maps) {
          setIsLoaded(true);
          return;
        }
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        console.log("Google Maps script caricato con successo!");
        setIsLoaded(true);
      };
      script.onerror = () => {
        console.error(
          "Errore durante il caricamento dello script di Google Maps."
        );
        setLoadError("Errore durante il caricamento di Google Maps.");
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    console.log(isLoaded);
  }, []);

  return (
    <div className="mt-5 d-flex flex-row align-items-center justify-content-center col-12 h-100">
      {isLoaded && (
        <>
          {/* // <LoadScript googleMapsApiKey={apiKey}> */}
          {/* <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={15}
            options={mapOptions}
            mapTypeId="satellite"
          >
            <Marker position={poiLocation} label="" />
          </GoogleMap> */}

          <MemorizedMap
            center={mapCenter}
            containerStyle={containerStyle}
            mapOptions={mapOptions}
            poiLocation={poiLocation}
          />

          {/* </LoadScript> */}
        </>
      )}
    </div>
  );
};

export default Maps;
