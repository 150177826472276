import Header from "../../components/Header/Header";
import styles from "./Contacts.module.scss";
import phoneIcon from "../../assets/phoneIcon.png";
import whatsappIcon from "../../assets/whatsappIcon.png";
import Maps from "../../components/Maps/Maps";

const Contacts: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center overflow-hidden">
        <div className={`${styles.firstDiv} col-12 mb-10 `}>
          <Header />

          <div
            className={`${styles.pageDiv}  mt-10  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
          >
            <>
              <span> Contatti ed Orari</span>

              <div className="col-12 d-flex flex-row align-items-start justify-content-around mt-10 text-tahoma  mb-4 ">
                <div className="col-5 d-flex flex-column align-items-start justify-content-start ">
                  <span className={` subtitle-tahoma fw-bold color-greyText`}>
                    Orari di apertura
                  </span>
                  <span className="col-12 mt-5 d-flex flex-row ">
                    <span className="col-2  fw-bold">Lunedi</span>
                    <span className="col-10  ps-4">Chiuso</span>
                  </span>
                  <span className="col-12 mt-3 d-flex flex-row ">
                    <span className="col-2  fw-bold">Martedi</span>
                    <span className="col-10  ps-4">
                      09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                    </span>
                  </span>
                  <span className="col-12 mt-3 d-flex flex-row ">
                    <span className="col-2  fw-bold">Mercoledi</span>
                    <span className="col-10  ps-4">
                      09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                    </span>
                  </span>
                  <span className="col-12 mt-3 d-flex flex-row ">
                    <span className="col-2  fw-bold">Giovedi</span>
                    <span className="col-10  ps-4">
                      09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                    </span>
                  </span>
                  <span className="col-12 mt-3 d-flex flex-row ">
                    <span className="col-2  fw-bold">Venerdi</span>
                    <span className="col-10  ps-4">
                      09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                    </span>
                  </span>
                  <span className="col-12 mt-3 d-flex flex-row ">
                    <span className="col-2  fw-bold">Sabato</span>
                    <span className="col-10  ps-4">
                      08:30-18:00 &nbsp;&nbsp; (Orario invernale)
                    </span>
                  </span>
                  <span className="col-12 mt-3 d-flex flex-row ">
                    <span className="col-2  fw-bold">Domenica</span>
                    <span className="col-10  ps-4">Chiuso</span>
                  </span>
                  <div className=" subtitle-tahoma d-flex flex-row align-items-start justify-content-start fw-bold mt-6 color-greyText">
                    Contattaci
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-start col-12  mt-5">
                    <span
                      className={`${styles.contactBtn} d-flex flex-row align-items-center justify-content-center col-3 fw-bold`}
                    >
                      <span className="col-2  me-3">
                        <img src={phoneIcon} className={` ${styles.btnimg}`} />
                      </span>
                      <span className="">Telefono</span>
                    </span>
                    <span
                      className={`${styles.contactBtn} d-flex flex-row align-items-center justify-content-center col-3 fw-bold ms-5`}
                    >
                      <span className="col-2  me-3">
                        <img
                          src={whatsappIcon}
                          className={` ${styles.btnimg}`}
                        />
                      </span>
                      Whatsapp
                    </span>
                  </div>
                </div>

                <div className="col-5 subtitle-tahoma   d-flex flex-column align-items-start justify-content-start fw-bold color-greyText">
                  Dove siamo
                  <div className="col-12 " style={{ height: "350px" }}>
                    <Maps />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
