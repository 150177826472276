import Header from "../../components/Header/Header";
import styles from "./Home.module.scss";
import backgroundImage from "../../assets/background.png";
import salone2 from "../../assets/salone2.png";
import salone1 from "../../assets/salone1.png";
import salone3 from "../../assets/salone3.png";
import phoneIcon from "../../assets/phoneIcon.png";
import whatsappIcon from "../../assets/whatsappIcon.png";
import takara from "../../assets/takara.png";
import depotMale from "../../assets/depotmale.png";
import zoneconcept from "../../assets/zone-concept.png";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Maps from "../../components/Maps/Maps";

const Home: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Scorre di una sola immagine e loop infinito
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 3000);
    return () => clearInterval(interval); // Pulizia dell'interval al momento della rimozione del componente
  }, []);

  const images = [salone2, salone1, salone3];
  const maleStyles = [salone2, salone1, salone3, salone2, salone1, salone3];
  const femaleStyles = [salone2, salone1, salone3, salone2, salone1];

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center overflow-hidden">
        <div className={`${styles.firstDiv} col-12`}>
          <img src={backgroundImage} />

          <Header />
          <div
            className={`d-flex flex-column align-items-center justify-content-center  color-white light-title-lato fw-bold ${styles.greatings}`}
          >
            Benvenuti nel
            <span className="title-lato fw-bold"> Salone Scavazzon</span>
            <span className="text-lato col-6 text-center">
              Il nostro salone è a conduzione familiare dal 1952, e attualmente
              è alla terza generazione. <br /> Passione e professionalità sono
              da sempre insiti nel nostro DNA da parrucchieri e possiamo offrire
              ai nostri clienti trattamenti e tagli all’ultima moda.
            </span>
            <div
              className={`d-flex flex-row col-5 align-items-center justify-content-center ${styles.buttonContainer} `}
            >
              <span
                className={`${styles.callBtn} text-tahoma d-flex flex-row align-items-center justify-content-center px-5 color-yellow fw-bold`}
              >
                Chiamaci
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pageDiv} py-5  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
        >
          <span> Il Salone</span>
          <div
            className={`d-flex flex-row align-items-center justify-content-start col-12 ${styles.imageScorrevole}`}
          >
            {images.map((image, index) => (
              <div key={index} className={`col-4 ${styles.imageContainer} `}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  //   style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={`${styles.pageDiv} py-5 mt-10  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
        >
          <span> I nostri lavori</span>
          <div className={`${styles.container} `}>
            {/* Prima riga (stili maschili) */}
            <div className={`${styles.row}`}>
              {maleStyles.map((image, index) => (
                <div key={index} className={`${styles.image_container}`}>
                  <img src={image} alt={`Male style ${index + 1}`} />
                </div>
              ))}
            </div>

            {/* Seconda riga (stili femminili) */}
            <div className={`${styles.row}`}>
              {femaleStyles.map((image, index) => (
                <div key={index} className={`${styles.image_container}`}>
                  <img src={image} alt={`Female style ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`${styles.pageDiv}  mb-10  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
        >
          <span> Recensioni </span>
          <div className="col-12 d-flex flex-column align-items-center justify-content-start mt-10 text-tahoma">
            <div className="d-flex flex-row align-items-center justify-content-around col-2 color-yellow px-5">
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-8  mt-4 text-lato fw-bold text-center">
              Professionali, abili, qualificati, al passo con i tempi e molto
              simpatici. <br /> Mix perfetto per un servizio a 5 stelle sia per
              lui che per lei , ad un costo più che giusto. <br /> Grazie a
              Mauro Luca e Ornella.
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-8  mt-4 text-tahoma fw-bold text-center">
              Andrea C.
            </div>

            <div className="d-flex flex-row align-items-center justify-content-around col-2 color-yellow px-5 mt-7">
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-8  mt-4 text-lato fw-bold text-center">
              Storico salone uomo e donna di Voltabarozzo e uno degli ultimi
              barbieri rimasti nei dintorni. <br /> Se avete una barba da tenere
              in ordine vale assolutamente la pena andare a trovare Mauro!
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-8  mt-4 text-tahoma fw-bold text-center">
              Simone G.
            </div>

            <div className="d-flex flex-row align-items-center justify-content-around col-2 color-yellow px-5 mt-7">
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
              <FontAwesomeIcon icon={faStar as IconProp} />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-8  mt-4 text-lato fw-bold text-center">
              Oltre a essere molto bravi e competenti, sono anche gentilissimi!
              <br />
              Sono molto soddisfatta del taglio che mi ha fatto Luca!
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-8  mt-4 text-tahoma fw-bold text-center">
              Bianca Elena B.
            </div>
          </div>
        </div>
        <div
          className={`${styles.pageDiv}  mt-10 mb-10 col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
        >
          <span> I nostri partner </span>
          <div className="col-12 d-flex flex-column align-items-center justify-content-start mt-10 text-tahoma  mb-4 ">
            <span className={`${styles.partnerContainer} col-3`}>
              <img src={takara} />
            </span>
          </div>
          <div className="col-12 d-flex flex-column align-items-center justify-content-start mt-5 text-tahoma  mb-4 ">
            <span className={`${styles.partnerContainer} col-3`}>
              <img src={zoneconcept} />
            </span>
          </div>
          <div className="col-12 d-flex flex-column align-items-center justify-content-start mt-5 text-tahoma  mb-4 ">
            <span className={`${styles.partnerContainer} col-3`}>
              <img src={depotMale} />
            </span>
          </div>
        </div>

        <div
          className={`${styles.pageDiv}  mt-10 mb-10 col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
        >
          <span> Contatti e Orari </span>
          <div className="col-12 d-flex flex-row align-items-start justify-content-around mt-10 text-tahoma  mb-4 ">
            <div className="col-5 d-flex flex-column align-items-start justify-content-start ">
              <span className={` subtitle-tahoma fw-bold color-greyText`}>
                Orari di apertura
              </span>
              <span className="col-12 mt-5 d-flex flex-row ">
                <span className="col-2  fw-bold">Lunedi</span>
                <span className="col-10  ps-4">Chiuso</span>
              </span>
              <span className="col-12 mt-3 d-flex flex-row ">
                <span className="col-2  fw-bold">Martedi</span>
                <span className="col-10  ps-4">
                  09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                </span>
              </span>
              <span className="col-12 mt-3 d-flex flex-row ">
                <span className="col-2  fw-bold">Mercoledi</span>
                <span className="col-10  ps-4">
                  09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                </span>
              </span>
              <span className="col-12 mt-3 d-flex flex-row ">
                <span className="col-2  fw-bold">Giovedi</span>
                <span className="col-10  ps-4">
                  09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                </span>
              </span>
              <span className="col-12 mt-3 d-flex flex-row ">
                <span className="col-2  fw-bold">Venerdi</span>
                <span className="col-10  ps-4">
                  09:00-12:30 &nbsp;&nbsp; 16:00-20:00
                </span>
              </span>
              <span className="col-12 mt-3 d-flex flex-row ">
                <span className="col-2  fw-bold">Sabato</span>
                <span className="col-10  ps-4">
                  08:30-18:00 &nbsp;&nbsp; (Orario invernale)
                </span>
              </span>
              <span className="col-12 mt-3 d-flex flex-row ">
                <span className="col-2  fw-bold">Domenica</span>
                <span className="col-10  ps-4">Chiuso</span>
              </span>
              <div className=" subtitle-tahoma d-flex flex-row align-items-start justify-content-start fw-bold mt-6 color-greyText">
                Contattaci
              </div>
              <div className="d-flex flex-row align-items-start justify-content-start col-12  mt-5">
                <span
                  className={`${styles.contactBtn} d-flex flex-row align-items-center justify-content-center col-3 fw-bold`}
                >
                  <span className="col-2  me-3">
                    <img src={phoneIcon} className={` ${styles.btnimg}`} />
                  </span>
                  <span className="">Telefono</span>
                </span>
                <span
                  className={`${styles.contactBtn} d-flex flex-row align-items-center justify-content-center col-3 fw-bold ms-5`}
                >
                  <span className="col-2  me-3">
                    <img src={whatsappIcon} className={` ${styles.btnimg}`} />
                  </span>
                  Whatsapp
                </span>
              </div>
            </div>

            <div className="col-5 subtitle-tahoma  d-flex flex-column align-items-start justify-content-start fw-bold color-greyText">
              Dove siamo
              <div className="col-12 " style={{ height: "350px" }}>
                <Maps />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
