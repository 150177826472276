import Header from "../../components/Header/Header";
import styles from "./AboutUs.module.scss";
import Mauro from "../../assets/mauro.jpeg";
import Ornella from "../../assets/Ornella.jpeg";
import Luca from "../../assets/Luca.jpeg";
import arrow from "../../assets/arrow.png";
import phoneIcon from "../../assets/phoneIcon.png";
import whatsappIcon from "../../assets/whatsappIcon.png";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Maps from "../../components/Maps/Maps";
import ItemRow from "../../components/ItemRow/ItemRow";

const AboutUs: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center overflow-hidden">
        <div className={`${styles.firstDiv} col-12 mb-10 `}>
          <Header />

          <div
            className={`${styles.pageDiv}  mt-10  col-12 d-flex flex-column align-items-center justify-content-start color-white light-title-tahoma fw-bold`}
          >
            <>
              <span> Chi Siamo</span>
              <div className=" col-8 d-flex flex-column align-items-center justify-content-center  mt-5">
                <span className="col-9 d-flex flex-row align-items-center justify-content-center text-lato text-center px-5">
                  Il nostro salone è a conduzione familiare dal 1952, e
                  attualmente è alla terza generazione. Il nostro Team è
                  composto da Mauro, Ornella e dal figlio Luca.
                </span>
                <span className="col-11 d-flex flex-row align-items-center justify-content-center text-lato text-center px-5 mt-4">
                  Passione e professionalità sono da sempre insiti nel nostro
                  DNA da parrucchieri e grazie a continui corsi e aggiornamenti,
                  possiamo offrire ai nostri clienti trattamenti e tagli
                  all’ultima moda. Il tutto assieme ad una grande empatia verso
                  il cliente, al fine di farlo sentire in un ambiente amichevole
                  e familiare.
                </span>
                <span className="col-11 d-flex flex-row align-items-center justify-content-center text-lato text-center px-5 mt-4">
                  Quindi, CHI SIAMO?  <br />
                  Siamo semplicemente i vostri fidati consulenti d’immagine.
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center col-10  mt-5">
                <div
                  className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                >
                  <span
                    className={`${styles.plan} col-10 color-white d-flex flex-column align-items-center justify-content-start `}
                  >
                    <img src={Mauro} className={`${styles.profileImg}`} />
                    <span className={`${styles.name} subtitle-tahoma fw-bold`}>Mauro </span>
                  </span>
                  <span
                    className={`${styles.planTrasled} col-10 color-white d-flex flex-column align-items-center justify-content-start`}
                  ></span>
                </div>
                <div
                  className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                >
                  <span
                    className={`${styles.plan} col-10 color-white d-flex flex-column align-items-center justify-content-start `}
                  >
                    <img src={Ornella} className={`${styles.profileImg}`} />
                    <span className={`${styles.name} subtitle-tahoma fw-bold`}>
                      Ornella
                    </span>
                  </span>
                  <span
                    className={`${styles.planTrasled} col-10 color-white d-flex flex-column align-items-center justify-content-start`}
                  ></span>
                </div>
                <div
                  className={`col-3  mx-5 d-flex flex-row align-items-center justify-content-center ${styles.planContainer}`}
                >
                  <span
                    className={`${styles.plan} col-10 color-white d-flex flex-column align-items-center justify-content-start `}
                  >
                    <img src={Luca} className={`${styles.profileImg}`} />
                    <span className={`${styles.name} subtitle-tahoma fw-bold`}>
                      Luca
                    </span>
                  </span>
                  <span
                    className={`${styles.planTrasled} col-10 color-white d-flex flex-column align-items-center justify-content-start`}
                  ></span>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
